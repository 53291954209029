import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { readable } from '../utilities/readable';
import classNames from 'classnames/bind';
import { timeTranslate } from '../utilities/time-translate';
import _ from 'lodash';
import Loading from '../components/loading';
import Triage from './triage';
import { MdChevronLeft, MdEmail, MdAssignmentReturned } from 'react-icons/md';
import Dropdown from 'react-bootstrap/Dropdown';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import moment from 'moment';
import SuccessAlert from '../components/success-alert';
import ScrollToTop from '../components/scroll-to-top';
import ExpandButton from '../components/expand-button';
import QuickpicUploader from '../components/quickpick-uploader';
import { getJobTriageStatus } from '../utilities/triageStatus';
import * as Scroll from 'react-scroll';
import { useDispatch } from '../state/useDispatch';
import { getSetting } from '../data/settings';
import InfoAlert from '../components/info-alert';
import FailureAlert from '../components/failure-alert';
import { useEffect } from 'react';
import { encodeQueryObject, getBaseUrl, getGlobalAuthParams } from '../actions/sdk';

const videoExtensions = new Set(['mp4', 'webm', 'ogg', 'mov']);

function isVideoUrl(url) {
	const extension = url.split('.').pop();
	return videoExtensions.has(extension.toLowerCase());
}

// format 1|W11578827|Acu,1|W11162561|capacitor,1|W11398781|Actuator
function parsePartList(partListString) {
	const parts = partListString.split(',').map(partString => {
		const [quantity, partNumber, description] = partString.split('|');
		return { quantity, partNumber, description };
	});

	return parts;
}
function PartParseAndListView({ parts }) {
	const [partList, setPartList] = useState(null);

	useEffect(() => {
		setPartList(parsePartList(parts));
	}, [parts]);

	if (!partList) return null;

	return partList.map((part, index) => {
		return (
			<div key={index} className="d-flex justify-content-between align-items-center mb-4">
				<div className="d-flex flex-column">
					<div><b>Quantity:</b> {part.quantity}</div>
					<div><b>Part Number:</b> {part.partNumber}</div>
					<div><b>Description:</b> {part.description}</div>
				</div>
			</div>
		);
	});
}

function canBeTriaged(job) {
	const triage1 = job.is_triaged;
	const triageWasDoneByOffice = triage1 === 1;
	const hasTriage = !!job?.triages?.[0];
	const isDateTriagable = job.applicable_date === '' || job.applicable_date === '0000-00-00' || moment(job.applicable_date, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day');
	const hasPriorVisits = !!parseInt(job.prior_visit_count) > 0;

	const result = { warnings: [], reasons_unable: [] };

	if (!isDateTriagable) {
		result.reasons_unable.push('Date of appointment is in the past');
	}

	if (job.applicable_date === '' || job.applicable_date === '0000-00-00') {
		result.warnings.push('Job is not scheduled yet');
	}

	if (triage1 && !hasTriage) {
		if (triageWasDoneByOffice) {
			result.warnings.push('Triage was marked as done by your office, and we cannot find a triage performed by a tech, depending on your company policy you may or may not want to triage this job, consult with your office');
		} else {
			result.warnings.push('Triage was marked as done, but no triage was found, this usually means something went wrong in the triage process, you may need to resubmit this triage');
		}
	} else if (triage1 && hasTriage) { // hasTriage is redundant, but here for clarity
		if (getSetting('allowMultipleTriage') === true) {
			result.warnings.push('Triage was already completed, but your settings allow multiple triages');
		} else {
			if (getSetting('allowTriageWhenTriageByOffice') && triageWasDoneByOffice) { // triage1 is 2 when tech performed the triage
				result.warnings.push('Triage was completed by your office, and your settings allow triages on jobs when existing triage was done by office');
			} else {
				result.reasons_unable.push('Triage was already completed, and your settings don\'t allow multiple triages');
				result.settings_blocked = true;
			}
		}
	} else if (!triage1 && hasTriage ) {
		result.warnings.push('Triage was not marked as done, but a triage was found');
	}

	if (hasPriorVisits) {
		if (!getSetting('allowTriageOnMultipleVisits')) {
			result.reasons_unable.push('This job has prior visits, and your settings do not allow triages on jobs with prior visits');
			result.settings_blocked = true;
		}
	}

	if (result.reasons_unable.length > 0) {
		result.can_triage = false;
	} else {
		result.can_triage = true;
	}

	return result;
}

export default function Dispatch(props) {
	const routeParams = useParams();
	const [expandMachineHistory, setExpandMachineHistory] = useState(false);
	const [dispatch, reloadDispatch, loaded] = useDispatch(routeParams.invoice, routeParams?.id);

	if (!loaded) {
		return <Loading />;
	}

	if (_.isEmpty(dispatch)) {
		return <SuccessAlert message="Looks like there's no dispatch here!" return={true} />;
	}

	let machineHistoryClasses = 'card-body bg-lighter position-relative hide-machine-history';
	if (expandMachineHistory) {
		machineHistoryClasses = 'card-body bg-lighter';
	}
	let stateColor;
	let cardState;
	let stateMessage = dispatch.status;
	switch (stateMessage) {
		case 'new':
			stateMessage = 'No status';
			stateColor = 'state-secondary';
			cardState = 'card-state';
			break;
		case 'confirmed':
			stateColor = 'state-info';
			cardState = 'card-state';
			break;
		case 'dispatched':
			stateColor = 'state-success';
			cardState = 'card-state';
			break;
		case 'pre_screened':
			stateMessage = 'triaged';
			stateColor = 'state-success';
			cardState = 'card-state';
			break;
		case 'notify_customer_requested':
			stateMessage = 'notify_requested';
			cardState = 'card-state';
			stateColor = 'state-danger';
			break;
		case 'incomplete_with_report':
			stateColor = 'state-danger';
			cardState = 'card-state';
			break;
		case 'complete_with_report':
			stateMessage = 'complete_with_report';
			cardState = 'card-state';
			stateColor = 'state-primary';
			break;
	}

	let stripUnderscores = stateMessage.replace(/_/g, ' ');
	let capitalizeFirst = stripUnderscores.charAt(0).toUpperCase() + stripUnderscores.slice(1);

	var stateClass = classNames(
		'mb-3',
		cardState,
		stateColor
	);


	let phoneCount = 0;
	let emailCount = 0;
	var timeFrame = timeTranslate(_.get(dispatch, 'appointment_start'), _.get(dispatch, 'appointment_end'), _.get(dispatch, 'appointment_string'));

	const authParams = getGlobalAuthParams();

	const sbWebAddress = `${getBaseUrl()}/v3/whirlpool/redirectEntitlement?${encodeQueryObject({
		model: dispatch?.machine?.model,
		serial: dispatch?.machine?.serial,
		business_id: authParams.business_id,
		tech_code: authParams.tech_code,
		tech_pass: authParams.tech_pass,
	})}`;

	let triage_status = getJobTriageStatus(dispatch);
	const canBeTriagedResult = canBeTriaged(dispatch);
	return (
		<div>
			<ScrollToTop />
			<Card className={stateClass} state={capitalizeFirst}>
				<h6 className='card-title border-bottom d-flex justify-content-between align-items-center'>
					<Link to="/" className="btn btn-sm btn-std"><MdChevronLeft /> Return</Link>
					<span>{readable((!_.get(dispatch, 'customer.name') && _.get(dispatch, 'job_title')) || _.get(dispatch, 'customer.name'))} - {_.get(dispatch, 'invoice_number')}</span>

					<Dropdown className="d-inline-block" alignRight>
						<Dropdown.Toggle id="dropdown-basic" className="btn btn-std">
							Actions
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item className="p-0"><Link to={`/sd-mail/${dispatch?.invoice_number}/${dispatch?.schedule_item_id}`} className="font-weight-bold text-decoration-none d-block px-4 py-2 text-dark"><MdEmail /> Send SD-Mail</Link></Dropdown.Item>
							{canBeTriagedResult?.can_triage && <Dropdown.Item className="p-0"><AnchorLink href="#triage" className="font-weight-bold text-decoration-none d-block px-4 py-2 text-dark"><MdAssignmentReturned /> Jump to Parts Triage</AnchorLink></Dropdown.Item>}
						</Dropdown.Menu>
					</Dropdown>
				</h6>
			</Card>

			<Card className="mb-3">
				<h6 className="card-title border-bottom">{!_.get(dispatch, 'customer.address1') && <span>No </span>}Location</h6>
				{_.get(dispatch, 'customer.address1') &&
					<div className="card-body bg-lighter">
						{_.get(dispatch, 'customer.address1') && <div><b>Street:</b> {readable(_.get(dispatch, 'customer.address1'))}</div>}
						{_.get(dispatch, 'customer.address2') && <div><b>City:</b> {_.get(dispatch, 'customer.address2')}</div>}
						{_.get(dispatch, 'customer.emails').map(email => {
							emailCount++;
							return (
								<div key={email}>
									<b>Email {emailCount}</b>: <a href={'mailto:' + email}>{email}</a>
								</div>
							);

						})}

						{_.get(dispatch, 'customer.phones').map(phone => {
							phoneCount++;
							return (
								<div key={phone.number}>
									<b>Phone {phoneCount}</b>{phone.description && <span className="text-black-50"> ({phone.description})</span>}: <a href={'tel:' + phone.number}>{phone.number}</a>
								</div>
							);

						})}
					</div>
				}
			</Card>

			<Card className="mb-3">
				<h6 className="card-title border-bottom d-flex justify-content-between align-items-center"><div>{!_.get(dispatch, 'machine.model') && (!_.get(dispatch, 'machine.serial') && <span>No </span>)} Unit Info</div> <a className="btn btn-std btn-sm" target="_blank" href={sbWebAddress} rel="noopener noreferrer">SB Web Inquiry</a></h6>
				{_.get(dispatch, 'machine.make') &&
					<div className="card-body bg-lighter">
						{_.get(dispatch, 'machine.make') && <div><b>Item Make:</b> {_.get(dispatch, 'machine.make')}</div>}
						{_.get(dispatch, 'machine.type') && <div><b>Item Type:</b> {_.get(dispatch, 'machine.type')}</div>}
						{_.get(dispatch, 'machine.model') && <div><b>Model Number:</b> {_.get(dispatch, 'machine.model')}</div>}
						{_.get(dispatch, 'machine.serial') && <div><b>Serial Number:</b> {_.get(dispatch, 'machine.serial')}</div>}
						{_.get(dispatch, 'machine.purchase_date') && <div><b>Purchase Date:</b> {_.get(dispatch, 'machine.purchase_date') && _.get(dispatch, 'machine.purchase_date').split('T')[0].replace('0000-00-00', 'None provided.')}</div>}
					</div>
				}
			</Card>

			<Card className="mb-3">
				<h6 className="card-title border-bottom">{!dispatch.appointment_string && <span>No </span>}Appointment Info</h6>
				{dispatch.appointment_string &&
					<div className="card-body bg-lighter">
						{dispatch.tech_code && <div><b>For Tech:</b> {dispatch.tech_code}</div>}
						{dispatch.appointment_string && <div><b>Appointment:</b> {dispatch.appointment_string}</div>}
						{dispatch.applicable_date && <div><b>Date:</b> {dispatch.applicable_date.split('T')[0]}</div>}
						{dispatch.appointment_string && <div><b>Time:</b> {timeFrame}</div>}
					</div>
				}
			</Card>

			<Card className="mb-3">
				<h6 className="card-title border-bottom">{!dispatch.description && <span>No </span>}Problem Description</h6>
				{dispatch.description &&
					<div className="card-body bg-lighter">
						{dispatch.description && <div>{dispatch.description}</div>}
					</div>
				}
			</Card>

			<Card className="mb-3">
				<h6 className="card-title border-bottom">{!dispatch.prior_visit_count && <span>No </span>}Prior Visits{dispatch.prior_visit_count && <span>: {dispatch.prior_visit_count}</span>}</h6>
				{dispatch.work_prior_done &&
					<div className="card-body bg-lighter">
						{dispatch.work_prior_done && <div><b>Prior Work:</b> {dispatch.work_prior_done}</div>}
					</div>
				}
			</Card>

			<Card className="mb-3">
				<h6 className="card-title border-bottom d-flex justify-content-between align-items-center"><span>{!_.get(dispatch, 'machine.history') && <span>No </span>}Machine History</span> {_.get(dispatch, 'machine.history') && <ExpandButton open={expandMachineHistory} handleToggleMachineHistory={() => setExpandMachineHistory(!expandMachineHistory)} />}</h6>
				{_.get(dispatch, 'machine.history') &&
					<div className={machineHistoryClasses}>
						{!expandMachineHistory && <div className="hide-machine-history-shadow"></div>}
						{_.get(dispatch, 'machine.history') && <div>{_.get(dispatch, 'machine.history').split('\n').map((item, dex) => <div key={`pick_${dex}`}>{item}</div>)}</div>}
					</div>
				}
			</Card>

			<Card className="mb-3">
				<h6 className="card-title">{_.isEmpty(dispatch.attention_notes) && <span>No </span>}Attention Notes</h6>
				<div className="list-group list-group-flush bg-lighter">
					{_.get(dispatch, 'attention_notes').map(note => {
						return (
							<div className="list-group-item" key={note.message}>
								<div><b>Date/Time:</b> {note.date}</div>
								<div><b>User:</b> {note.user}</div>
								<div><b>Message:</b> {note.message}</div>
							</div>
						);
					})}
				</div>
			</Card>

			<Card className="mb-3">
				<h6 className="card-title border-bottom">{_.isEmpty(dispatch.photos) && <span>No </span>}Quickpics</h6>
				<div className="bg-lighter d-flex flex-wrap">
					{_.get(dispatch, 'photos').map(photo => {
						return (
							<a key={photo.url} className="p-2 w-25" href={`https://atlas.rossware.com/DisplayImage.php?id=${photo.user_id}${photo.id}`} target="_blank" rel="noopener noreferrer">
								<div className="p-2 w-100">
									{isVideoUrl(photo.url) ? (
										<video src={photo.url} className="rounded" width="100%" controls />
									) : (
										<img src={photo.url} className="rounded" width="100%" />
									)}
									<div>Photo taken by: {photo.tech_code}</div>
									<div>{photo.when_uploaded}</div>
								</div>
							</a>
						);
					})}
				</div>
				<QuickpicUploader invoiceNumber={dispatch?.invoice_number} afterUpload={reloadDispatch} />
			</Card>

			<Card className="mb-3">
				<h6 className="card-title">{!dispatch.future_parts_pick && <span>No </span>}Parts Currently Assigned</h6>
				{_.get(dispatch, 'future_parts_pick') &&
					<div className="list-group list-group-flush bg-lighter">
						{dispatch.future_parts_pick.map(part => {
							return (
								<div className="list-group-item" key={part.part_number}>
									<div><b>Quantity:</b> {part.quantity}</div>
									<div><b>Part Number:</b> {part.part_number}</div>
									<div><b>Description:</b> {part.description}</div>
								</div>
							);
						})}
					</div>
				}
			</Card>

			<Card className="mb-3">
				<h6 className="card-title border-bottom">{!dispatch.extra_notes && <span>No </span>}Extra Notes</h6>
				{dispatch.extra_notes &&
					<div className="card-body bg-lighter">
						{dispatch.extra_notes && <div>{dispatch.extra_notes}</div>}
					</div>
				}
			</Card>

			{canBeTriagedResult.can_triage && !!canBeTriagedResult.warnings.length && canBeTriagedResult.warnings.map(warning =>
				<InfoAlert key={warning} message={warning} />
			)}

			{canBeTriagedResult.can_triage && <Triage
				id={_.get(dispatch, 'schedule_item_id')}
				model={_.get(dispatch, 'machine.model')}
				symptom={_.get(dispatch, 'description')}
				invoice_number={_.get(dispatch, 'invoice_number')}
				reloadJob={reloadDispatch}
				// alreadySubmitted={triage_status}
				// setScroll={() => this.setScrollPosition()}
			/>}

			{!canBeTriagedResult.can_triage && (
				<Card className="mb-3">
					<h6 className="card-title">Cannot Triage</h6>

					{!!canBeTriagedResult.reasons_unable.length && canBeTriagedResult.reasons_unable.map(message =>
						<FailureAlert key={message} message={message} />
					)}

					{canBeTriagedResult.settings_blocked && <div className="card-body bg-lighter">
						<Link to="/settings">settings</Link>
					</div>}
				</Card>
			)}

			{(dispatch.triages || []).map(submitted => {
				const { TechCode, Parts, Notes, TriageDate, RcvdBySD } = submitted;

				return (
					<Card key={`past_triage_${TriageDate}`} className="mb-3">
						<h6 className="card-title border-bottom">Triage by {TechCode} on {TriageDate}</h6>
						<div className="card-body bg-lighter">
							{Notes ? <div>{Notes}</div> : <div style={{ opacity: .6 }}>NO NOTES</div>}
						</div>
						<div className="card-body bg-lighter">
							<PartParseAndListView parts={Parts} />
						</div>
					</Card>
				);
			})}

		</div>
	);
}
